import { Subject } from 'rxjs/Subject';
import { Modal } from 'shared/components/modal/modal.reducers';

const fromModals: SelectorCreator<NavCarrierState['modals']> = innerSelector => (state, ownProps) => innerSelector(state.modals, ownProps);

export function modals<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
  return fromModals(modalsState => ({ modals: modalsState }))(state, ownProps);
}

export namespace modals {
  export const visible = fromModals<{ visible: boolean }, { name: string }>((state, ownProps) => ({
    visible: state[ownProps.name] ? state[ownProps.name].visible : false
  }));
  export const context = fromModals<{ context: Modal['context'] }, { name: string }>((state, ownProps) => ({
    context: state[ownProps.name] ? state[ownProps.name].context : {}
  }));
  export const visibilityChanges = fromModals<{ visibilityChanges: Subject<boolean> }, { name: string }>((state, ownProps) => ({
    visibilityChanges: state[ownProps.name] ? state[ownProps.name].visibilityChanges : new Subject()
  }));
  export const select = fromModals;
}
