import { mapResources } from 'app/i18n/resources';

export const fromResources: SelectorCreator<Resources> = innerSelector => (s, o) => innerSelector(s.resources, o);

export function resources<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
  return fromResources(resourcesState => ({ resources: resourcesState }))(state, ownProps);
}

export namespace resources {
  export const select = fromResources;
  export const getResources = (...resourceKeys: ResourceKey[]) =>
    fromResources(innerResources => ({ resources: mapResources(innerResources, ...resourceKeys) }));
}
