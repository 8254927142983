import { selectByKey } from './selector-utils';

import { refData as refDataSelectors } from './state/ref-data.selectors';
import { app as appSelectors } from './state/app.selectors';

import { culture as cultureSelectors } from './state/culture.selectors';
import { modals as modalsSelectors } from './state/modals.selectors';
import { loading as loadingSelectors } from './state/loading.selectors';
import { auth as authSelectors } from './state/auth.selectors';
import { resources as resourcesSelectors } from './state/resources.selectors';
import { router as routerSelectors } from './state/router.selectors';

export const fromState: SelectorCreator<NavCarrierState> = innerSelector => (s, ownProps) => innerSelector(s, ownProps);

export function select<TProps, TOwnProps>(selector: Selector<NavCarrierState, TProps, TOwnProps>): Selector<NavCarrierState, TProps, TOwnProps> {
  return fromState(selector);
}

export namespace select {
  export const app = appSelectors;
  export const resources = resourcesSelectors;
  export const router = routerSelectors;
  export const auth = authSelectors;
  export const modals = modalsSelectors;
  export const loading = loadingSelectors;
  export const culture = cultureSelectors;
  export const refData = refDataSelectors;
  export const toasts = fromState(selectByKey('toasts'));
  export const captcha = fromState(selectByKey('captcha'));
}
