import { AppState } from 'store';
import { selectByKey } from '../selector-utils';

const fromApp: SelectorCreator<AppState> = innerSelector => (state, ownProps) => innerSelector(state.app, ownProps);

export function app<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
  return fromApp(appState => ({ app: appState }))(state, ownProps);
}

export namespace app {
  export const select = fromApp;
  export const defaultCountry = fromApp(selectByKey('defaultCountry'));
  export const initialized = fromApp(selectByKey('initialized'));
  export const isDetailView = fromApp(selectByKey('isDetailView'));
}
