export const fromCulture: SelectorCreator<NavCarrierState['culture']> = innerSelector => (s, o) => innerSelector(s.culture, o);

export function culture<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
  return fromCulture(cultureState => ({ culture: cultureState }))(state, ownProps);
}

export namespace culture {
  export const locale = fromCulture(cultureState => ({ locale: cultureState?.locale }));
  export const country = fromCulture(cultureState => ({ country: cultureState?.country }));
  export const select = fromCulture;
}
