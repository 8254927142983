const fromLoading: SelectorCreator<NavCarrierState['loading']> = innerSelector => (state, ownProps) => innerSelector(state.loading, ownProps);

export function loading<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
  return fromLoading(loadingState => ({ loading: loadingState }))(state, ownProps);
}

export namespace loading {
  export const visible = fromLoading<{ visible: boolean }, { name: string }>((state, ownProps) => ({ visible: Boolean(state[ownProps.name]) }));
  export const select = fromLoading;
}
