import { User } from 'shared/models/user.model';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';

const fromUser: SelectorCreator<User> = innerSelector => (state, ownProps) => innerSelector(state?.auth?.user, ownProps);

export function user<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
  return fromUser(currentUser => ({ user: currentUser }))(state, ownProps);
}

export namespace user {
  export const isMetric = fromUser(currentUser => ({ isMetric: currentUser ? currentUser.isMetric() : null }));
  export const unitOfMeasure = fromUser(currentUser => ({
    unitOfMeasure: (currentUser?.properties)
      // server returns UnitOfMeasure.US (2) for users
      // this cannot be used on many API endpoints,
      // so it needs to be subbed for UnitOfMeasure.Standard (0)
      ? (currentUser.properties.unitOfMeasure === UnitOfMeasure.Metric ? UnitOfMeasure.Metric : UnitOfMeasure.Standard)
      : null
  }));
  export const regionCode = fromUser(currentUser => ({ regionCode: currentUser?.getCarrier()?.regionCode }));
  export const is12Hour = fromUser(currentUser => ({ is12Hour: currentUser?.is12Hour() }));
  export const isEuropean = fromUser(currentUser => ({ isEuropean: currentUser?.isEuropean() }));
  export const isNorthAmerican = fromUser(currentUser => ({ isNorthAmerican: currentUser?.isNorthAmerican() }));
  export const carrierCode = fromUser(currentUser => ({ carrierCode: currentUser?.carrierCode }));
  export const isDriver = fromUser(currentUser => ({ isDriver: currentUser?.isDriver }));
  export const email = fromUser(currentUser => ({email: currentUser?.email}));
}
