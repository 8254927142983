import { selectByKey } from '../../selector-utils';
import { RefDataState } from 'store';

const fromLocation: SelectorCreator<RefDataState['location']> = innerSelector => (s, o) => innerSelector(s.refData.location, o);
export function location(state: NavCarrierState, ownProps) {
  return fromLocation(locationState => ({ location: locationState }))(state, ownProps);
}

export namespace location {
  export const countries = fromLocation(selectByKey('countries'));
  export const filteredCountries = filter => (fromLocation(locationState => ({ countries: locationState.countries.filter(filter) })));
  export const states = fromLocation(selectByKey('states'));
  export const statesByCountry = (countryId: number) => fromLocation(locationState => ({ states: locationState.states[countryId] || [] }));
}
