import { selectByKey } from '../../selector-utils';

const fromUser: SelectorCreator<AuthState['pending']> = innerSelector => (state, ownProps) => innerSelector(state.auth.pending, ownProps);

export function pending<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
 return fromUser(pendingValue => ({ pending: pendingValue }))(state, ownProps);
}

export namespace pending {
 export const carrier = fromUser(selectByKey('carrier'));
 export const userJSON = fromUser(selectByKey('userJSON'));
}
