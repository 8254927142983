import { RefDataState } from 'store';
import { selectByKey } from '../selector-utils';
import { location as locationSelectors } from './ref-data/location.selectors';

const fromRefData: SelectorCreator<RefDataState> = innerSelector => (s, o) => innerSelector(s.refData, o);
export function refData(state: NavCarrierState, ownProps) {
  return fromRefData(refDataState => ({ refData: refDataState }))(state, ownProps);
}

export namespace refData {
  export const equipmentTypes = fromRefData(selectByKey('equipmentTypes'));
  export const extendedEquipmentTypes = fromRefData(selectByKey('extendedEquipmentTypes'));
  export const specializedEquipmentTypes = fromRefData(selectByKey('specializedEquipmentTypes'));
  export const loadFilters = fromRefData(selectByKey('loadFilters'));
  export const location = locationSelectors;
  export const select = fromRefData;
}
