import { RouterState } from 'connected-react-router';
import { selectByKey } from '../selector-utils';

export const fromRouter: SelectorCreator<RouterState> = innerSelector => (s, o) => innerSelector(s.router, o);

export function router<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
  return fromRouter(routerState => ({ router: routerState }))(state, ownProps);
}

export namespace router {
  export const select = fromRouter;
  export const location = fromRouter(selectByKey('location'));
}
